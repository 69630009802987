<i18n>
{
  "en": {
    "browser_title": "Playlist Detail",
    "public": "Public",
    "private": "Private",
    "movie_count": "no movies | 1 movie | movies",
    "last_updated": "Last updated on",
    "update_playlist_0": "Updated playlist '",
    "update_playlist_1": "' successfully",
    "alert_name_exists": "This playlist name already exists",
    "alert_name_invalid": "Invalid playlist name"
  },
  "ja": {
    "browser_title": "プレイリスト情報",
    "public": "公開",
    "private": "非公開",
    "last_updated": "最終更新日:",
    "movie_count": "0 本の動画 | 1 本の動画 | 本の動画",
    "update_playlist_0": "再生リスト「",
    "update_playlist_1": "」が更新されました",
    "alert_name_exists": "この名前はすでに使用されています",
    "alert_name_invalid": "無効な名前です"
  }
}
</i18n>

<template>
<main>
  <vue-headful v-bind:title="dynamicTitle" />

  <div class="playlist-detail container page-margin">
    <div v-if="playlistEnabled && isSiteMember">
      <div class="heading">
        <h1 v-if="playlist">
          <span v-show="!editPlaylistName">
            {{ playlistName }} <span v-if="playlist.type !== 'default'" class="btn-edit" @click="enablePlaylistNameEdit()"><i class="icon-pencil"></i></span>
          </span>
          <span v-show="editPlaylistName">
            <input
              type="text"
              class="input-edit"
              ref="inputPlaylistName"
              v-model="playlistName"
              :maxlength="maxChars"
              @blur="savePlaylistName"
              @keydown.enter="savePlaylistName"
              @keydown.esc="cancelPlaylistNameEdit">
            <div class="limit">{{ maxChars - playlistName.length }}/{{ maxChars }}</div>
            <div class="alert" v-if="showAlert && !alertIsClear">{{ $t(alertText) }}</div>
          </span>
        </h1>
      </div>
      <div class="playlist-data" v-if="playlist">
        <div class="privacy-switch" v-if="publicListsEnabled && playlist.type == 'custom'">
          <div class="privacy-switch-inner">
            <div class="toggle" :class="{ selected: isPublic }" @click="togglePrivacy(true);clickTrack('Playlist', 'Privacy Set', 'Public')"><p>{{ $t('public') }}</p></div>
            <div class="toggle" :class="{ selected: !isPublic }" @click="togglePrivacy(false);clickTrack('Playlist', 'Privacy Set', 'Private')"><p>{{ $t('private') }}</p></div>
          </div>
        </div>
        <!-- default lists are always private -->
        <div class="privacy-switch-default" v-if="publicListsEnabled && playlist.type == 'default'">{{ $t('private') }}</div>
        <div class="privacy-info">
          <!-- movie count && updated date -->
          <span v-if="playlist.totalMovieCount > 1">{{ playlist.totalMovieCount }} {{ $tc('movie_count', 2) }}</span>
          <span v-else-if="playlist.totalMovieCount == 1">{{ $tc('movie_count', 1) }}</span>
          <span v-else>{{ $tc('movie_count', 0) }}</span>
          <span v-if="playlist.lastupdated">&nbsp;&bull;&nbsp; {{ $t('last_updated') }} {{ formatDate(playlist.lastupdated) }}</span>
        </div>
      </div>

      <!-- movie list -->
      <movie-list
        :playlist-id="playlistId"
        @movieCount="onMovieCountChange"/>
    </div>
  </div>
</main>
</template>

<script>
/* eslint max-len: 0 */

import vueHeadful from 'vue-headful';
import validator from 'validator/validator';
import moment from 'moment';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import EventBus from '@/assets/js/utils/EventBus';
import APP_CONFIG from '@/appConfig';
import MovieList from '@/components/playlistDetail/movieList.vue';

const bfAPI = new BifrostAPI();

export default {
  components: {
    'vue-headful': vueHeadful,
    'movie-list': MovieList,
  },
  data() {
    return {
      playlist: null,
      playlists: [],
      playlistId: this.$route.query.list,
      playlistName: '',
      isPublic: false,
      editPlaylistName: false,
      dynamicTitle: '',
      invalidName: '', // save invalid name so we know when to clear alert state
      showAlert: false,
      alertText: '',
    };
  },
  created() {
    // non-reactive consts
    this.maxChars = 100;

    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);

    if (this.isSiteMember) this.getPlaylistDetail();
  },
  methods: {
    getPlaylistDetail() {
      bfAPI.getPlaylistMovies(this.playlistId, 0, 1).then((response) => {
        if (response.status === 200) {
          this.playlist = response.data.data;

          // set translated playlist name by app locale (only for default playlists)
          if (this.playlist.type === 'default') {
            this.playlistName = this.translationMap[this.playlist.name][this.locale];
          } else {
            this.playlistName = this.playlist.name;
          }
          this.isPublic = this.playlist.isPublic;
        }
      });
    },
    updatePlaylist(playlistName) {
      bfAPI.updatePlaylist(this.playlistId, this.playlist.type, playlistName, this.isPublic).then((response) => {
        if (response.status === 200 && response.data.updated) {
          this.playlist.name = playlistName;
          this.editPlaylistName = false;
          // show update success notification
          EventBus.$emit('notification:event:generic', {
            text: this.$t('update_playlist_0') + playlistName + this.$t('update_playlist_1'),
            duration: 3000,
          });
        } else if (response.status === 200 && !response.data.updated) {
          this.invalidName = playlistName;
          // duplicated custom names, show alert
          this.showAlert = true;
          this.alertText = 'alert_name_exists';
        }
      });
    },
    togglePrivacy(isPublic) {
      this.isPublic = isPublic;
      // if this is a default playlist, do not update playlist name
      // we don't want localized playlist name gets saved in db, use the original default playlist name here
      if (this.playlistName === 'liked') {
        this.updatePlaylist('liked');
      } else if (this.playlistName === 'watch_later') {
        this.updatePlaylist('watch_later');
      } else {
        this.updatePlaylist(this.playlistName);
      }
    },
    enablePlaylistNameEdit() {
      this.editPlaylistName = true;
      if (this.editPlaylistName) {
        this.$nextTick(() => {
          this.$refs.inputPlaylistName.focus();
        });
      }
    },
    cancelPlaylistNameEdit() {
      this.editPlaylistName = false;
      this.playlistName = this.playlist.name;
    },
    sanitizeInput(input) {
      // trim(): trim characters (whitespace by default) from both sides of the input
      // escape(): replace <, >, &, ', " and / with HTML entities
      let cleanInput = input.replace(/(<([^>]+)>)/ig, '');
      cleanInput = validator.trim(cleanInput);
      cleanInput = validator.escape(cleanInput);

      return cleanInput;
    },
    savePlaylistName() {
      // only save if in edit mode -- the functions called by the keydown events also trigger blur,
      // so we'll need to first check if the input field is editable before we proceed with
      // everything else to do with updating the playlist name
      if (this.editPlaylistName
        && this.playlist.type !== 'default'
        && this.playlistName !== this.playlist.name) {
        // sanitize user input
        let plName = this.sanitizeInput(this.playlistName);

        if (plName === '') {
          // if sanitizeInput() returns empty, meaning input contains only illegal script tags
          // which have been replaced completey; clear input box and display alert msg
          this.invalidName = this.playlistName;
          this.playlistName = '';
          this.showAlert = true;
          this.alertText = 'alert_name_invalid';
        } else {
          // replace multiple whitespaces with 1 whitespace to prevent extra spaces between words
          plName = plName.replace(/\s+/g, ' ');
          // check if user input playlist name is same as our default playlist names (case insensitive)
          Object.keys(this.translationMap).forEach((pl) => {
            if (plName.toLowerCase() === this.translationMap[pl][this.locale].toLowerCase()) {
              this.playlistName = plName;
              this.invalidName = plName;
              this.showAlert = true;
              this.alertText = 'alert_name_exists';
            }
          });
        }

        if (!this.showAlert || this.alertIsClear) {
          this.updatePlaylist(plName);
        }
      }
    },
    onMovieCountChange(num) {
      // triggered when the child component emits the `movieCount` event, for incrementing or
      // decrementing the movie count and the 'last updated' date
      if (this.playlist) {
        this.playlist.totalMovieCount += num;
        this.$set(this.playlist, 'lastupdated', new Date().toISOString());
      }
    },
    formatDate(date) {
      return moment(String(date), 'YYYY-MM-DD').format('YYYY/MM/DD');
    },
    clickTrack(category, action, label = null, value = null) {
      this.$analytics.trackEvent(category, action, label, value);
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    isSiteMember() {
      return this.$store.getters['user/isSiteMember'];
    },
    playlistEnabled() {
      return this.$store.getters['playlist/isEnabled'];
    },
    publicListsEnabled() {
      return this.$store.getters['playlist/isPublicListsEnabled'];
    },
    translationMap() {
      return this.$store.getters['playlist/translationMap'];
    },
    alertIsClear() {
      // TRUE if there is no alert -OR- if there is an alert and the current input name is different
      // than the last saved invalid name
      return ((this.showAlert && this.playlistName !== this.invalidName) || (!this.showAlert));
    },
  },
  watch: {
    isSiteMember(newVal, oldVal) {
      if (oldVal !== newVal && newVal) {
        // is a member, fetch playlist
        this.getPlaylistDetail();
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/_playlist.scss';
</style>
