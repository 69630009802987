<i18n>
{
  "en": {
    "vip_badge": "VIP",
    "svip_badge": "SVIP",
    "annual_badge": "Annual",
    "limited_tag": "Limited Availability",
    "expired_tag": "Availability Ended",
    "vr_tag": "VR",
    "btn_load_more": "Load More",
    "btn_to_top": "Back to Top",
    "mdl_title_save_to": "Save to...",
    "mdl_title_confirm": "Confirm",
    "mdl_text_confirm": "Are you sure you want to remove this movie?",
    "mdl_placeholder_create": "Give your playlist a name",
    "mdl_btn_create_new_playlist": "Create New Playlist",
    "mdl_btn_create_new_playlist_disabled": "Playlist limit reached",
    "mdl_btn_create": "Create",
    "mdl_btn_cancel": "Cancel",
    "mdl_btn_confirm_remove": "Yes, remove it!",
    "ntf_add_playlist_0": "Added to playlist '",
    "ntf_add_playlist_1": "' successfully",
    "ntf_remove_playlist_0": "Removed from playlist '",
    "ntf_remove_playlist_1": "' successfully",
    "alert_name_exists": "This playlist name already exists",
    "alert_name_invalid": "Invalid playlist name"
  },
  "ja": {
    "vip_badge": "VIP",
    "svip_badge": "超VIP",
    "annual_badge": "年間",
    "limited_tag": "期間限定",
    "expired_tag": "配信期間終了",
    "vr_tag": "VR",
    "btn_load_more": "もっと見る",
    "btn_to_top": "トップヘ戻る",
    "mdl_title_save_to": "保存先...",
    "mdl_title_confirm": "確認",
    "mdl_text_confirm": "この再生リストを削除してもよろしいですか？",
    "mdl_placeholder_create": "再生リストの名前を入力",
    "mdl_btn_create_new_playlist": "新しい再生リストを作成",
    "mdl_btn_create_new_playlist_disabled": "再生リスト数の上限に達しました",
    "mdl_btn_create": "作成",
    "mdl_btn_cancel": "キャンセル",
    "mdl_btn_confirm_remove": "削除",
    "ntf_add_playlist_0": "「",
    "ntf_add_playlist_1": "」に追加しました",
    "ntf_remove_playlist_0": "「",
    "ntf_remove_playlist_1": "」から削除されました",
    "alert_name_exists": "この名前はすでに使用されています",
    "alert_name_invalid": "無効な名前です"
  }
}
</i18n>

<template>
<div class="playlist-movie-list">
  <!-- movie list -->
  <transition-group name="list" tag="div" class="grid">
    <div class="grid-item" v-for="(movie, index) in movieListSorted" :key="movie.MovieID">
      <div class="entry">
        <div class="entry-playlist">
          <div class="playlist-number">{{ index + 1 }}</div>
        </div>
        <router-link :to="`/movies/${movie.MovieDateID}/`" class="entry-media">
          <div v-if="!newXPEligible && movie.isVIP" class="tag-member is-vip">{{ $t('vip_badge') }}</div>
          <div v-if="!newXPEligible && movie.isSVIP" class="tag-member is-svip">{{ $t('svip_badge') }}</div>
          <div v-if="!newXPEligible && movie.isAnnual" class="tag-member is-annual">{{ $t('annual_badge') }}</div>
          <div class="media-thum">
            <img :src="`${movie.thumbnailSrc}`"
              @error="useFallbackImage(index)"
              class="media-image"
              loading="lazy"
              v-sfw
            />
          </div>
        </router-link>
        <div class="entry-meta">
          <router-link :to="`/movies/${movie.MovieDateID}/`">
            <div v-if="locale == 'ja'">
              <div class="meta-title">{{ movie.Title }}</div>
            </div>
            <div v-if="locale == 'en'">
              <div v-if="movie.TitleEn != null && movie.TitleEn != ''" class="meta-title">{{ movie.TitleEn }}</div>
              <div v-else class="meta-title" v-for="(actress, index) in movie.ActressesEn" :key="index">{{ actress }}</div>
            </div>
            <div v-if="movie.IsExpired" class="tag-status tag-status--expired">{{ $t('expired_tag') }}</div>
            <div v-if="movie.IsLimited" class="tag-status tag-status--limited">{{ $t('limited_tag') }}</div>
            <div v-if="movie.isVR" class="tag-status tag-status--vr">{{ $t('vr_tag') }}</div>
          </router-link>
          <div class="list-action">
            <div class="btn-save" @click="showPlaylistModal(movie, index)"><i class="icon-playlist_add"></i></div>
            <div class="btn-remove" @click="showConfirmModal(movie, index)"><i class="icon-trash-o"></i></div>
          </div>
        </div>
      </div>
    </div>
  </transition-group>
  <button v-if="movieListSorted.length > 0 & !isEnd" class="button is-small button-load" @click="loadPlaylistMovies">{{ $t('btn_load_more') }}</button>
  <button v-if="isEnd && movieListSorted.length > limit" class="button is-small button-top" @click="scrollToTop"><i class="iconf-keyboard-arrow-left"></i>{{ $t('btn_to_top') }}<i class="iconf-keyboard-arrow-left"></i></button>
  <!-- /.list -->

  <!-- add to playlist modal -->
  <modal name="ui-modal-playlist" class="modal-overwrite" draggable="false">
    <div class="playlist-modal">
      <div class="modal-close">
        <span class="modal-close-icon" @click="closePlaylistModal"></span>
      </div>
      <div class="modal-title">{{ $t('mdl_title_save_to') }}</div>
      <div class="modal-section">
        <ul>
          <li v-for="playlist in sortedFilteredPlaylists" :key="playlist.playlist_id">
            <input :name="`checkbox-${playlist.playlist_id}`" type="checkbox" :checked="playlist.checked" @change="updatePlaylist(playlist.playlist_id, playlist.name, selectedMovieId, selectedMovieDateId, playlist.checked)">
            <label :for="`checkbox-${playlist.playlist_id}`" @click="updatePlaylist(playlist.playlist_id, playlist.name, selectedMovieId, selectedMovieDateId, playlist.checked)">{{ playlist.type === 'default'? translationMap[playlist.name][locale] : playlist.name }}</label>
          </li>
        </ul>
      </div>
      <!-- create new playlist section -->
      <div class="modal-section center" v-if="customListsEnabled">
        <button
          class="button is-filled is-color-main is-small"
          v-if="!showInput && numCustomLists < customListLimit"
          @click="showInput = true">{{ $t('mdl_btn_create_new_playlist') }}</button>
        <button
          class="button is-filled is-color-main is-small"
          v-else-if="!showInput && numCustomLists >= customListLimit"
          disabled>{{ $t('mdl_btn_create_new_playlist_disabled') }}</button>
        <div class="section-input bottom" v-else>
          <input type="text" :placeholder="$t('mdl_placeholder_create')" :maxlength="maxChars" v-model="newPlaylistName" @focus="showAlert = false">
          <div class="limit">{{ maxChars - newPlaylistName.length }}/{{ maxChars }}</div>
          <div class="alert" v-if="showAlert">{{ $t(alertText) }}</div>
          <button class="button is-filled is-color-main is-small" :disabled="!newPlaylistName.length" @click="addToNewPlaylist(newPlaylistName)">{{ $t('mdl_btn_create') }}</button>
        </div>
      </div>
    </div>
  </modal>

  <!-- confirm cancel modal -->
  <modal name="ui-modal-confirm" class="modal-overwrite" draggable="false">
    <div class="confirm-modal">
      <div class="modal-close">
        <span class="modal-close-icon" @click="closeConfirmModal"></span>
      </div>
      <div class="modal-title">{{ $t('mdl_title_confirm') }}</div>
      <div class="modal-section">
        <span class="section-top">{{ $t('mdl_text_confirm') }}</span>

        <button class="button-close button is-filled is-color-main is-small" @click="closeConfirmModal">{{ $t('mdl_btn_cancel') }}</button>
        <button class="button is-filled is-color-main is-small" @click="deleteMovieFromPlaylist(playlistId, playlistName, selectedMovieId, selectedMovieDateId)">{{ $t('mdl_btn_confirm_remove') }}</button>
      </div>
    </div>
  </modal>
</div>
</template>

<script>
/* eslint no-console: 0 */
/* eslint max-len: 0 */
/* eslint no-shadow: 0 */

import orderBy from 'lodash/orderBy';
import validator from 'validator/validator';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import Analytics from '@/assets/js/utils/Analytics';
import MovieHelper from '@/assets/js/utils/movie';
import EventBus from '@/assets/js/utils/EventBus';

const bfAPI = new BifrostAPI();

export default {
  props: {
    playlistId: {
      type: String,
    },
  },
  data() {
    return {
      movieListSorted: [],
      playlists: [],
      playlistName: '',
      totalMovieCount: 0,
      selectedMovieId: '',
      selectedMovieDateId: '',
      selectedIndex: null,
      selectedMovie: {}, // placholder for movie data in case it gets re-added to the playlist
      showInput: false, // flag for new playlist input
      showAlert: false, // flag for alert message
      alertText: '',
      newPlaylistName: '',
      posterFallbackTries: {},
      offset: 0,
      gaTitleList: {},
      gaActressList: {},
    };
  },
  created() {
    // non-reactive consts
    this.maxChars = 100;
    this.limit = 20;

    if (this.isSiteMember) this.loadPlaylistMovies();
  },
  methods: {
    loadPlaylistMovies() {
      bfAPI.getPlaylistMovies(this.playlistId, this.offset, this.limit).then((response) => {
        if (response.data.data.movieData.length) {
          this.playlistName = response.data.data.name;
          this.totalMovieCount = response.data.data.totalMovieCount;
          const movieList = response.data.data.movieData;

          // walk thru movie list and do some things
          Object.keys(movieList).forEach((index) => {
            // get more movie details
            movieList[index] = MovieHelper.expandDetails(movieList[index], this.locale, this.userTypeID);

            // re-set movie 'thumbnailSrc' because expandDetails() uses 'MovieID' in src path instead of 'MovieDateID'
            // which results in src not found and then triggers useFallbackImage() to use large poster
            this.$set(movieList[index], 'thumbnailSrc', `/assets/sample/${movieList[index].MovieDateID}/l_thum.jpg`);
            this.movieListSorted.push(movieList[index]);

            // save movie title/actress(es) for GA4 custom events
            this.$set(this.gaTitleList, movieList[index].MovieDateID, movieList[index].Title);
            this.$set(this.gaActressList, movieList[index].MovieDateID, movieList[index].ActressesJa);
          });

          // manage paging data
          this.offset += this.limit;
        }
      });
    },
    refreshMovieList() {
      // reset pagination
      this.offset = 0;

      // reset sorted list
      this.movieListSorted = [];

      this.loadPlaylistMovies();
      this.scrollToTop();
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getPlaylists(selectedMovieId) {
      bfAPI.getPlaylists().then((response) => {
        if (response.status === 200) {
          this.playlists = response.data.data;

          // this part is to pre-select playlist(s) that the selected movie has been added to already
          let movieList = {};
          for (let i = 0; i < this.playlists.length; i += 1) {
            movieList = this.playlists[i].movies; // format: {"12345": 1, "22345": 1}
            const movieIds = Object.keys(movieList); // format: ['12345', '22345']

            for (let j = 0; j < movieIds.length; j += 1) {
              // convert to proper movie ID type
              const movieId = parseInt(movieIds[j], 10);

              // set checkbox value = true if movie ID matches
              if (movieId === selectedMovieId) {
                this.$set(this.playlists[i], 'checked', true);
              }
            }
          }
        }
      });
    },
    addMovieToPlaylist(playlistId, playlistName, selectedMovieId, selectedMovieDateId) {
      bfAPI.addMovieToPlaylist(playlistId, selectedMovieId).then((response) => {
        if (response.status === 200) {
          // show notification
          EventBus.$emit('notification:event:generic', {
            text: this.$t('ntf_add_playlist_0') + ((typeof this.translationMap[playlistName] !== 'undefined') ? this.translationMap[playlistName][this.locale] : playlistName) + this.$t('ntf_add_playlist_1'),
            duration: 50000,
          });

          // analytics
          this.$analytics.trackEvent('Playlist', 'Add Movie to Playlist', selectedMovieDateId);
          Analytics.sendCustomEvent('playlist', {
            playlist_action: 'Add Movie to Playlist',
            playlist_type: (typeof this.translationMap[playlistName] !== 'undefined') ? 'Default' : 'Custom',
            playlist_name: (typeof this.translationMap[playlistName] !== 'undefined') ? this.translationMap[playlistName].en : playlistName,
            movie_id: selectedMovieDateId,
            movie_title: this.gaTitleList[selectedMovieDateId],
            actress_name: this.gaActressList[selectedMovieDateId].toString(),
          });

          // only refresh movie list when adding(checking) movie from current playlist and only if
          // we are on the last page because we know that's the "true" end of the list
          if (playlistId === this.playlistId) {
            if (this.isEnd) this.movieListSorted.push(this.selectedMovie);

            // update parent movie count
            this.$emit('movieCount', 1);
          }

          // reset fallback image state since the list will re-render at the next step
          this.posterFallbackTries = {};

          // get updated playlists for modal
          this.getPlaylists(selectedMovieId);
        }
      });
    },
    deleteMovieFromPlaylist(playlistId, playlistName, selectedMovieId, selectedMovieDateId) {
      bfAPI.deleteMovieFromPlaylist(playlistId, selectedMovieId).then((response) => {
        if (response.status === 200) {
          // show notification
          EventBus.$emit('notification:event:generic', {
            text: this.$t('ntf_remove_playlist_0') + ((typeof this.translationMap[playlistName] !== 'undefined') ? this.translationMap[playlistName][this.locale] : playlistName) + this.$t('ntf_remove_playlist_1'),
            duration: 50000,
          });

          // analytics
          this.$analytics.trackEvent('Playlist', 'Delete Movie from Playlist', selectedMovieDateId);
          Analytics.sendCustomEvent('playlist', {
            playlist_action: 'Delete Movie from Playlist',
            playlist_type: (typeof this.translationMap[playlistName] !== 'undefined') ? 'Default' : 'Custom',
            playlist_name: (typeof this.translationMap[playlistName] !== 'undefined') ? this.translationMap[playlistName].en : playlistName,
            movie_id: selectedMovieDateId,
            movie_title: this.gaTitleList[selectedMovieDateId],
            actress_name: this.gaActressList[selectedMovieDateId].toString(),
          });

          // only refresh movie list when deleting(unchecking) movie from current playlist
          if (playlistId === this.playlistId) {
            if (this.selectedIndex >= 0) this.movieListSorted.splice(this.selectedIndex, 1);

            // if we are not on the last page, reduce offset by 1 (otherwise the next page request
            // will be missing a movie since we just deleted a movie from the current page)
            if (!this.isEnd) this.offset -= 1;

            // update parent movie count
            this.$emit('movieCount', -1);
          }

          // reset fallback image state since the list will re-render at the next step
          this.posterFallbackTries = {};

          // get updated playlists for modal
          this.getPlaylists(selectedMovieId);
        }
      });

      this.closeConfirmModal();
    },
    updatePlaylist(playlistId, playlistName, selectedMovieId, selectedMovieDateId, isChecked) {
      if (!isChecked) {
        this.addMovieToPlaylist(playlistId, playlistName, selectedMovieId, selectedMovieDateId);
      } else {
        this.deleteMovieFromPlaylist(playlistId, playlistName, selectedMovieId, selectedMovieDateId);
      }
    },
    addToNewPlaylist(playlistName) {
      // sanitize user input
      let plName = this.sanitizeInput(playlistName);

      if (plName === '') {
        // if sanitizeInput() returns empty, meaning input contains only illegal script tags
        // which have been replaced completey; clear input box and display alert msg
        this.newPlaylistName = '';
        this.showAlert = true;
        this.alertText = 'alert_name_invalid';
      } else {
        // replace multiple whitespaces with 1 whitespace to prevent extra spaces between words
        plName = plName.replace(/\s+/g, ' ');
        // check if user input playlist name is same as our default playlist names (case insensitive)
        Object.keys(this.translationMap).forEach((pl) => {
          if (plName.toLowerCase() === this.translationMap[pl][this.locale].toLowerCase()) {
            this.newPlaylistName = plName;
            this.showAlert = true;
            this.alertText = 'alert_name_exists';
          }
        });
      }

      if (!this.showAlert) {
        // 2 steps here: first create new playlist, then add the selected movie to this new playlist
        bfAPI.createPlaylist('custom', plName, false).then((response) => {
          // if a new playlist is created you'll see the data property
          // otherwise if the playlist already exists you'll just get the playlistCount property
          if (response.status === 201 && response.data.data) {
            const newPlaylistId = response.data.data.playlist_id;
            this.addMovieToPlaylist(newPlaylistId, plName, this.selectedMovieId, this.selectedMovieDateId);
            this.closePlaylistModal();

            // analytics
            this.$analytics.trackEvent('Playlist', 'Create Playlist', plName);
            Analytics.sendCustomEvent('playlist', {
              playlist_action: 'Add Playlist',
              playlist_type: 'Custom',
              playlist_name: plName,
            });
          } else if (response.status === 201 && !response.data.data) {
            this.newPlaylistName = plName;
            this.showAlert = true;
            this.alertText = 'alert_name_exists';
          } else if (response.status === 400 && response.data.error === 'playlist_name invalid') {
            this.newPlaylistName = '';
            this.showAlert = true;
            this.alertText = 'alert_name_invalid';
          }
        });
      }
    },
    sanitizeInput(input) {
      // trim(): trim characters (whitespace by default) from both sides of the input
      // escape(): replace <, >, &, ', " and / with HTML entities
      let cleanInput = input.replace(/(<([^>]+)>)/ig, '');
      cleanInput = validator.trim(cleanInput);
      cleanInput = validator.escape(cleanInput);

      return cleanInput;
    },
    showPlaylistModal(movie, index) {
      this.resetPlaylistModal();
      this.getPlaylists(movie.MovieID);
      this.selectedMovieId = movie.MovieID;

      // save MovieDateID for ga click event
      this.selectedMovieDateId = movie.MovieDateID;

      // save the index so we know where to splice the movie list
      this.selectedIndex = index;

      // save the movie object so we can place it at the bottom of the list if the user unchecks and
      // then rechecks to add it back to the playlist while the modal is open
      this.selectedMovie = movie;

      this.$modal.show('ui-modal-playlist');
    },
    closePlaylistModal() {
      this.resetPlaylistModal();
      this.$modal.hide('ui-modal-playlist');
    },
    resetPlaylistModal() {
      this.showInput = false;
      this.showAlert = false;
      this.newPlaylistName = '';
      this.selectedMovieId = '';
      this.selectedMovieDateId = '';
      this.selectedIndex = null;
      this.selectedMovie = {};

      // reset checkbox value = false
      for (let i = 0; i < this.playlists.length; i += 1) {
        this.playlists[i].checked = false;
      }
    },
    showConfirmModal(movie, index) {
      this.resetPlaylistModal();
      this.selectedMovieId = movie.MovieID;
      this.selectedMovieDateId = movie.MovieDateID;
      this.selectedIndex = index;
      this.$modal.show('ui-modal-confirm');
    },
    closeConfirmModal() {
      this.$modal.hide('ui-modal-confirm');
    },
    useFallbackImage(index) {
      // missing movie poster thumbnail, fallback to large poster
      if (!this.posterFallbackTries[this.movieListSorted[index].MovieDateID]) {
        // attempt 1: poster thumbnail is missing, use large poster
        this.$set(this.movieListSorted[index], 'thumbnailSrc', `/assets/sample/${this.movieListSorted[index].MovieDateID}/str.jpg`);
        this.$set(this.posterFallbackTries, [this.movieListSorted[index].MovieDateID], true);
      } else {
        // attempt 2: large poster is missing, use no-image placeholder
        this.$set(this.movieListSorted[index], 'thumbnailSrc', '/img/common/now_printing_square.png');
      }
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
    isSiteMember() {
      return this.$store.getters['user/isSiteMember'];
    },
    numCustomLists() {
      return this.playlists.filter(playlist => playlist.type === 'custom').length;
    },
    customListLimit() {
      return this.$store.getters['playlist/custom_limit'];
    },
    customListsEnabled() {
      return this.$store.getters['playlist/isCustomListsEnabled'];
    },
    translationMap() {
      return this.$store.getters['playlist/translationMap'];
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
    sortedFilteredPlaylists() {
      // sort playlists by default types first (desc order), and then playlist name by alpha (asc
      // order), and then filter if applicable
      const orderedList = orderBy(this.playlists, ['type', 'name'], ['desc', 'asc']);
      const filteredList = (!this.customListsEnabled) ? orderedList.filter(item => item.type === 'default') : orderedList;

      return filteredList;
    },
    isEnd() {
      // are we on the last page of the list or not; making this a computed value (instead of being
      // set with the 'page' and 'offset' values) so that if the user has 21 movies and then
      // deletes 1, the "load more" button will reactively go away
      return (this.offset >= this.totalMovieCount);
    },
  },
  watch: {
    isSiteMember(newVal, oldVal) {
      if (oldVal !== newVal && newVal) {
        // is a member, request playlist movies
        this.loadPlaylistMovies();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
